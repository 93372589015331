.App {
  text-align: center;
}

#pano {
  canvas {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
