.slide--three {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  color: #ffffff;
  position: relative;

  img {
    object-fit: cover;
    height: 640px;
  }

  @media screen and (min-width: 1023px) {
    img {
      height: auto;
    }
  }
}
