#header {
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 50px;
  z-index: 90;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;

  #logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  #menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}
