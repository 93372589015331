.slide--one {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: #ffffff;
  position: relative;
  width: 100%;

  .menu--container {
    position: absolute;
    left: 0%;
    top: 12%;
    font-size: 32px;
    margin-right: 16%;
    margin-left: 3%;
    padding: 5px;
    cursor: pointer;
  }

  .title {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .viewer {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .info-content {
    display: flex;
    justify-content: space-around;
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    flex: 1;

    .info {
      width: 30%;

      .title {
        text-align: center;
        font-size: 12px;
        line-height: 15px;
        opacity: 0.4;
      }

      .content {
        text-align: center;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .scroll-down {
    margin-bottom: 10px;
    position: absolute;
    bottom: 5px;
    left: 50%;
  }

  .menu--container-desktop {
    position: absolute;
    background: #ffffff;
    border-radius: 13px;
    padding: 1rem;
    width: 15%;
    left: 5%;
    top: 25%;
    z-index: 1002;

    ul {
      li {
        text-align: left;
        font-weight: bold;
      }
    }
  }

  @media screen and (min-width: 1023px) {
    flex-direction: row;

    .menu--container {
      top: 20%;
    }

    > .title {
      margin-top: 50px;
      align-items: start;
      position: absolute;
      left: 8%;
    }

    .info-content {
      position: absolute;
      right: 8%;
      flex-direction: column;
      justify-content: start;
      top: 20%;

      .info {
        display: flex;
        flex-direction: column;
        width: 100%;

        .title {
          justify-content: start;
          font-size: 20px;
          line-height: 25px;
        }

        .content {
          text-align: start;
          font-size: 28px;
          line-height: 35px;
        }
      }
    }
  }
}

button {
  display: inline-block;
  border: none;
  font-family: Fjalla One;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  // padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: #ffffff;
  color: #000000;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover,
button:focus {
  color: #ef2c3a;
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}
