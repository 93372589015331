body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(000, 000, 000, 0.4);
  z-index: 1000;
}

.modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal {
  color: #000000;
  background: #ffffff;
  padding: 1rem;
  width: 80%;
  border-radius: 8px;
  z-index: 1002;
}

.modal-header {
  position: relative;

  h2 {
    margin: 0 0 1rem 0;
  }
}

.modal-close-button {
  color: #000000;
  font-size: 2rem;
  position: absolute;
  right: 0;
  top: -8px;
}

@media screen and (min-width: 1023px) {
  .modal {
    width: 20%;

    &.menu {
      position: fixed;
      top: 25%;
      left: 4%;
    }
  }
}
