.slide--two {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  color: #ffffff;
  padding: 16px;
  position: relative;

  .title {
    text-transform: uppercase;
    font-size: 36px;
  }

  img {
    width: 100%;
    height: auto;
  }

  p {
    text-align: left;
  }

  @media screen and (min-width: 1023px) {
    align-items: center;

    img {
      width: 60%;
    }

    p {
      width: 50%;
      // padding: 0 0rem 7rem 9rem;
      font-size: 1.5rem;
      line-height: 40px;
      margin: 0;
    }
  }
}
