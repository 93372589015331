.slide--six {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  color: #000000;
  position: relative;

  .title {
    padding: 16px;
    font-size: 36px;
    text-align: left;
  }

  img {
    object-fit: cover;
    width: 100%;
  }

  p {
    padding: 16px;
    text-align: left;
  }

  @media screen and (min-width: 1023px) {
    .title {
      margin-left: 17.5rem;
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      img {
        padding: 0 5rem 5rem 5rem;
      }

      p {
        width: 70%;
        padding: 0 0rem 7rem 9rem;
        font-size: 1.5rem;
        line-height: 40px;
        margin: 0;
      }
    }
  }
}
