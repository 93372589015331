.slide--seven {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../assets/images/slides/ff_gfxx100_4-opt.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;

  .title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 8px;
  }

  .subtitle {
    margin-top: 0;
    margin-bottom: 36px;
  }

  p {
    padding: 16px;
    text-align: center;
    font-size: 24px;
  }
}
